import { ResponseBody } from '@tager/web-core';
import { request } from '../models/Request';

import {
  FileType,
  SingleCourseFormParams,
  VacancyFormParams,
  VacancyFullType,
  VacancyShortType,
} from '@/typings/model';

import {
  MenuItemType,
  PageFullType,
  PageShortType,
  SettingsItemCommon,
  SeoParamsType,
} from '@tager/web-modules/src/typings/models';

export type FormPayload = {
  name: string;
  company?: string;
  phone?: string;
  email: string;
  message: string;
  file: number | null;
};

export function sendContactsForm(payload: FormPayload): Promise<FormPayload> {
  return request.post({ path: '/leads/feedback', body: payload });
}

export function sendCvForm(
  payload: FormPayload,
  params?: { vacancyId?: number }
): Promise<FormPayload> {
  return request.post({
    path: '/leads/cv',
    body: { ...payload, vacancyId: params ? params.vacancyId : null },
  });
}

export function uploadFile(file: File): Promise<FileType> {
  const formData = new FormData();
  formData.append('file', file);

  return request.post({ path: '/upload/feedback', body: formData });
}

export function getCareersVacancyByAlias(
  alias: string
): Promise<ResponseBody<VacancyFullType>> {
  return request.get({ path: `/vacancies/${alias}` });
}

export function getCareersVacanciesList(): Promise<{
  data: ResponseBody<Array<VacancyShortType>>;
  meta: {
    page: {
      number: number;
      size: number;
      count: number;
    };
    total: number;
  };
}> {
  return request.get({ path: `/vacancies` });
}

export function submitFormCourses(page: number, email: string) {
  return request.post({ path: '/leads/subscription', body: { page, email } });
}

export function sendSingleCourseForm(params: SingleCourseFormParams) {
  return request.post({
    path: '/leads/course',
    body: params,
  });
}

export function sendVacancyForm(params: VacancyFormParams) {
  return request.post({ path: '/leads/vacancy', body: params });
}

export function getSettingItemList<
  SettingItemType extends SettingsItemCommon = SettingsItemCommon
>(): Promise<ResponseBody<Array<SettingItemType>>> {
  return request.get({ path: '/tager/settings' });
}

export function getSeoParamsByAlias(
  alias: string
): Promise<ResponseBody<SeoParamsType>> {
  return request.get({ path: `/tager/seo/template/${alias}` });
}

export function getMenuItemListByAlias(
  menuAlias: string
): Promise<ResponseBody<Array<MenuItemType>>> {
  return request.get({ path: `/tager/menus/${menuAlias}` });
}

export function getPageList(): Promise<ResponseBody<Array<PageShortType>>> {
  return request.get({ path: '/tager/pages' });
}

export function getPageByPath<Page extends PageFullType = PageFullType>(
  pagePath: string
): Promise<ResponseBody<Page>> {
  return request.get({ path: `/tager/pages/view`, params: { path: pagePath } });
}