// @ts-nocheck
import { ApiService } from '@tager/web-core';

type BodyParam = { [key: string]: any } | FormData;

class ClientApiService extends ApiService {
	private configureHeaders(body?: BodyParam): Headers {
		const headers = super.configureHeaders(body);
		headers.append('X-Real-IP', global.ip);
		headers.append('X-Forwarded-For', global.forwardedFor);
		return headers;
	}
}

export const api = new ClientApiService();

export const request = api.getRequest();